import { usePureEffect } from '@buzzeasy/shared-frontend-utilities';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { ThemeProvider } from 'styled-components';
import useHandleIntegration from '../hooks/useHandleIntegration';
import useMediaQuery from '../hooks/useMediaQuery';
import useSoundNotification from '../hooks/useSoundNotification';
import ChatBoxLayout from '../layouts/ChatBoxLayout';
import WidgetLayout from '../layouts/WidgetLayout';
import { ParticipantType } from '../models/ChatMessage';
import { IHistoryItem } from '../models/content-manger/HistoryItem';
import { IHeaderUpdate } from '../models/signalR/HeaderUpdate';
import { IMessageToCustomer } from '../models/signalR/ToCustomerMessage';
import { ITypingIndicator } from '../models/signalR/TypingIndicator';
import { ITheme, IWidgetSettings, defaultColorTheme } from '../models/signalR/WidgetConfiguration';
import ChatButton from '../presenters/ChatButton';
import { messageInputId } from '../presenters/InputBox';
import NotificationBar from '../presenters/Notification';
import PopupMessage from '../presenters/PopupMessage';
import { useConfigContext } from '../providers/ConfigProvider';
import { addConversationHistory, addEndConversation, addMessage, addProactiveMessage, clearPopupMessage, clearUnreadMessageCounter, removeTypingIndicator, setIsConversationEnded, showAgentTypingIndicator, updateProactiveMessageDisplayState } from '../redux/chat/chatSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setHeaderData, setIsConversationDataSent, setMenuState, setNotificationMessage, setupWidget } from '../redux/widget/widgetSlice';
import useChatHub from '../signalr/useChatHub';
import useCustomerTrackingHub, { getTrackingDetails } from '../signalr/useCustomerTrackingHub';
import TranslationService from '../translations/translationService';
import { isFirefox } from '../utils/browserUtils';
import { OnActivateHandler } from '../utils/buttonHelper';
import { normalizeTrackingDetails } from '../utils/conversationDataHelper';
import { markConversationAsActive } from '../utils/conversationHelper';
import { widgetRootId } from '../utils/webChatConstants';
import ChatMessagesContainer from './ChatMessagesContainer';
import HeaderContainer from './HeaderContainer';
import InputContainer from './InputContainer';

const isOpenStorageKey = 'buzzeasy_chat_is_open';

const WidgetContainer: FC = () => {
  const config = useConfigContext();
  const defaultBodyOverflow = useMemo(() => document.body.style.overflow, []);
  const defaultBodyPosition = useMemo(() => document.body.style.position, []);

  const dispatch = useAppDispatch();

  const playSoundNotification = useSoundNotification();

  const [theme, setTheme] = useState<ITheme>(defaultColorTheme);
  const [isOpen, setIsOpen] = useState(!!sessionStorage.getItem(isOpenStorageKey));
  const [isInitialized, setInitialized] = useState(false);
  const [isFullScreenMode, setFullScreenMode] = useState(window.innerWidth < 400 || window.innerHeight < 640);

  const connectionStatus = useAppSelector(state => state.widget.connectionStatus);
  const unreadMessageCount = useAppSelector(state => state.chat.unreadMessageCount);
  const notificationMessage = useAppSelector(state => state.widget.notificationMessage);
  const messages = useAppSelector(state => state.chat.messages);
  const isConversationEnded = useAppSelector(state => state.chat.isConversationEnded);
  const popupMessage = useAppSelector(state => state.chat.popupMessage);
  const promotionalMessageTitle = useAppSelector(state => state.widget.headerData.subtitle);

  const proactiveMessageTimeoutRef = useRef<NodeJS.Timeout>();
  const typingTimeoutRef = useRef<NodeJS.Timeout>();

  const handleWithViewportChanges = useCallback(
    (isMatch: boolean) => {
      setFullScreenMode(isMatch);
      setTheme(prevTheme => ({
        ...prevTheme,
        isFullScreenMode: isMatch,
      }));
    },
    [],
  );

  useMediaQuery('(max-width: 400px), (max-height: 640px)', handleWithViewportChanges);

  const handleMessageReceived = useCallback(
    (message: IMessageToCustomer) => {
      markConversationAsActive(config.channelId);
      dispatch(addMessage(message));

      const notCustomerMessage = message.participantType !== ParticipantType.Customer;
      playSoundNotification(notCustomerMessage && !document.hasFocus());

      if (message.participantType === ParticipantType.Agent) {
        setIsOpen(true);
      }
    }, [config.channelId, dispatch, playSoundNotification],
  );

  const handleHeaderUpdate = useCallback(
    (headerData: IHeaderUpdate) => {
      dispatch(setHeaderData(headerData));
    }, [dispatch],
  );

  const handleOnConversationEnded = useCallback(
    () => {
      dispatch(setIsConversationEnded(true));
      dispatch(addEndConversation());
      dispatch(setIsConversationDataSent(false));
      dispatch(setMenuState(false));
    }, [dispatch],
  );

  const handleOnTyping = useCallback(
    (typingIndicator: ITypingIndicator) => {
      dispatch(showAgentTypingIndicator(typingIndicator));

      if (!typingIndicator.keepTypingIndicator) {
        clearTimeout(typingTimeoutRef.current);
        typingTimeoutRef.current = setTimeout(() => {
          dispatch(removeTypingIndicator());
        }, 3500);
      }
    },
    [dispatch],
  );

  const chatHub = useChatHub(
    {
      onMessageReceived: handleMessageReceived,
      onHeaderUpdated: handleHeaderUpdate,
      onConversationEnded: handleOnConversationEnded,
      onTyping: handleOnTyping,
    },
  );

  useCustomerTrackingHub(
    {
      onStartConversation: (conversationData) => {
        markConversationAsActive(config.channelId);
        const trackingDetails = normalizeTrackingDetails(getTrackingDetails());
        chatHub.startConversationWithAgent({ ...conversationData, agentData: { ...conversationData.agentData, workflowProperties: trackingDetails } });
      },
    },
  );

  const scrollTargetRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = useCallback(
    () => {
      if (isFirefox())
        // seemingly FF can't combine smooth and block: end (so we sacrifice smoothness)
        scrollTargetRef.current?.scrollIntoView(false);
      else
        scrollTargetRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    },
    [],
  );

  const openCloseChatWidget: OnActivateHandler = useCallback(
    e => {
      // toggle and persist isOpen
      setIsOpen(current => {
        const newIsOpen = !current;
        if (newIsOpen)
          sessionStorage.setItem(isOpenStorageKey, 'yes');
        else
          sessionStorage.removeItem(isOpenStorageKey);
        return newIsOpen;
      });

      dispatch(clearPopupMessage());
      dispatch(clearUnreadMessageCounter());
      scrollToBottom();

      if (proactiveMessageTimeoutRef.current) {
        clearTimeout(proactiveMessageTimeoutRef.current);
        proactiveMessageTimeoutRef.current = undefined;
      }

      e.stopPropagation();
    },
    [dispatch, scrollToBottom],
  );

  const closeHeaderMenu: React.MouseEventHandler<HTMLDivElement> = useCallback(
    () => {
      dispatch(setMenuState(false));
    },
    [dispatch],
  );

  const downloadHistory = useCallback(
    async (threadId: string, cmUrl: string, cmApiKey: string) => {
      try {
        const response = await fetch(`${cmUrl}v2/conversationhistory/webchat?tenant_id=${config.tenantId}&thread_id=${threadId}`, {
          method: 'GET',
          headers: {
            'API-KEY': cmApiKey,
          },
        });
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        const data = await response.json();
        dispatch(addConversationHistory(data as IHistoryItem[]));
        scrollToBottom();
      }
      catch {
        return dispatch(addConversationHistory([]));
      }
    },
    [config.tenantId, dispatch, scrollToBottom],
  );

  useHandleIntegration(isInitialized, proactiveMessageTimeoutRef.current, setIsOpen);

  usePureEffect(
    ({ dispatch: _dispatch, chatHub: _chatHub, scrollToBottom: _scrollToBottom }) => {
      async function initialize() {
        const settings: IWidgetSettings = JSON.parse(JSON.stringify(config.settings));
        settings.theme.isFullScreenMode = isFullScreenMode;
        settings.theme.isMobileDevice = isMobile;
        setTheme(settings.theme);
        const {
          contentManagerApiKey,
          contentManagerUrl,
          threadId,
          proactiveMessage,
          language,
        } = settings;

        await downloadHistory(threadId, contentManagerUrl, contentManagerApiKey);

        _dispatch(setupWidget(settings));

        const { isActive, headerInfo } = config.conversationStatus;
        if (headerInfo) {
          _dispatch(setHeaderData(headerInfo));
        }

        if (isActive) {
          _dispatch(setIsConversationDataSent(true));
          _chatHub.start();
        }
        else if (proactiveMessage) {
          _dispatch(addProactiveMessage(proactiveMessage));

          if (proactiveMessage.delayInSeconds) {
            proactiveMessageTimeoutRef.current = setTimeout(() => _dispatch(updateProactiveMessageDisplayState(proactiveMessage)), proactiveMessage.delayInSeconds * 1000);
          }
          else {
            _dispatch(updateProactiveMessageDisplayState(proactiveMessage));
          }

          _scrollToBottom();
        }

        TranslationService.setCurrentLanguage(language);

        const widgetRoot = document.getElementById(widgetRootId);
        if (widgetRoot) {
          if (widgetRoot.dir)
            TranslationService.overrideLanguageDirection(widgetRoot.dir);
          else
            widgetRoot.dir = TranslationService.getDirection();
        }
      }

      if (!isInitialized) {
        initialize();
        setInitialized(true);
      }

    },
    [isInitialized, downloadHistory, config, isFullScreenMode],
    { dispatch, chatHub, scrollToBottom },
  );

  usePureEffect(
    (_dispatch) => {
      const resetSettings = async () => {
        const settings: IWidgetSettings = config.settings;
        _dispatch(setupWidget(settings));
      };

      if (isInitialized && connectionStatus === 'disconnected') {
        resetSettings();
      }
    },
    [connectionStatus, isInitialized, config.settings],
    dispatch,
  );

  usePureEffect(
    (_dispatch) => {
      if (notificationMessage) {
        const timeout = setTimeout(() => _dispatch(setNotificationMessage(undefined)), 3000);
        return () => clearTimeout(timeout);
      }
    },
    [notificationMessage],
    dispatch,
  );

  usePureEffect(
    ({ dispatch: _dispatch, scrollToBottom: _scrollToBottom }) => {
      _dispatch(setIsConversationEnded(false));
      _scrollToBottom();
    },
    [messages, config],
    { dispatch, scrollToBottom },
  );

  usePureEffect(
    ({ chatHub: _chatHub, scrollToBottom: _scrollToBottom }) => {
      if (isConversationEnded) {
        _chatHub.disconnect();
        _scrollToBottom();
      }
    },
    [isConversationEnded],
    { chatHub, scrollToBottom },
  );

  useEffect(
    () => {
      if (isOpen && !isMobile)
        document.getElementById(messageInputId)?.focus();
    },
    [isOpen],
  );

  useEffect(
    () => {
      if (isFullScreenMode) {
        document.body.style.overflow = isOpen ? 'hidden' : defaultBodyOverflow;
        document.body.style.position = isOpen ? 'fixed' : defaultBodyPosition;
      }
    },
    [isOpen, isFullScreenMode, defaultBodyOverflow, defaultBodyPosition],
  );

  if (!isInitialized)
    return null;

  return (
    <ThemeProvider theme={theme}>
      <WidgetLayout
        isOpen={isOpen}
        onTransitionEnd={isOpen ? scrollToBottom : undefined}
        isStandaloneView={config.standaloneMode}
        chatBox={
          <ChatBoxLayout
            isFullscreenView={isFullScreenMode || config.standaloneMode}
            onClick={closeHeaderMenu}
            header={
              <HeaderContainer
                displayCloseButton={isFullScreenMode && !config.standaloneMode}
                hub={chatHub}
                onMinimize={() => setIsOpen(false)}
              />
            }
            notificationBar={
              (connectionStatus === 'reconnecting' || connectionStatus === 'disconnected' || notificationMessage) &&
              <NotificationBar notificationMessage={notificationMessage} connectionStatus={connectionStatus} />
            }
            messages={
              <ChatMessagesContainer
                innerRef={scrollTargetRef}
                hub={chatHub}
                onMessageLoaded={scrollToBottom}
              />
            }
            input={<InputContainer hub={chatHub} />}
          />
        }
        button={
          <ChatButton
            isOpen={isOpen}
            unreadMessagesCount={unreadMessageCount}
            onClick={openCloseChatWidget}
          />
        }
        popupMessage={
          (!isOpen && popupMessage) &&
          <PopupMessage
            messageTitle={promotionalMessageTitle}
            message={popupMessage}
            onMessageClick={openCloseChatWidget}
            onDismiss={() => dispatch(clearPopupMessage())}
          />
        }
        attentionHooks={config.settings.attentionHooks}
      />
    </ThemeProvider>
  );
};

export default WidgetContainer;
