import { darken } from 'polished';
import { FC, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import ChatMessagesLayout from '../layouts/ChatMessagesLayout';
import { IChatMessage } from '../models/ChatMessage';
import ChatItem from '../presenters/ChatItem';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setIsConversationDataSent } from '../redux/widget/widgetSlice';
import { IChatHub } from '../signalr/useChatHub';
import { getConversationData, IConversationData } from '../utils/conversationDataHelper';

const ChatContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${props => props.theme.background};

  ::-webkit-scrollbar {
    width: 0.6rem;
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.headerBackground};
    border-radius: 9999px;
    cursor: pointer;

    :hover {
      background-color: ${props => darken(0.05, props.theme.headerBackground)};
    }
  }
`;

interface IProps {
  innerRef: React.RefObject<HTMLDivElement>;
  hub: IChatHub;
  onMessageLoaded?(): void;
}

const ChatMessagesContainer: FC<IProps> = ({ innerRef, hub, onMessageLoaded }) => {

  const dispatch = useAppDispatch();

  const shouldSendConversationData = useAppSelector(state => !state.widget.isConversationDataSent);

  const messages = useAppSelector(state => state.chat.messages);

  const sendTextMessage = useCallback(
    async (payload: string) => {
      let conversationData: Partial<IConversationData> = {};

      if (shouldSendConversationData) {
        conversationData = await getConversationData();
        dispatch(setIsConversationDataSent(true));
      }

      hub.sendCustomerMessage(payload, conversationData);
    },
    [dispatch, hub, shouldSendConversationData],
  );

  const items = useMemo(
    () => messages.map((msg: IChatMessage) => (<ChatItem key={msg.key} message={msg} onLoad={onMessageLoaded} sendSuggestedActionResponse={sendTextMessage} />)),
    [messages, onMessageLoaded, sendTextMessage],
  );

  return (
    <ChatContainer>
      <ChatMessagesLayout>
        {items}
      </ChatMessagesLayout>
      <div
        style={{ float: 'left', clear: 'both' }} // hidden div for scrolling
        ref={innerRef}
        role="none"
      />
    </ChatContainer>
  );
};

export default ChatMessagesContainer;