import { usePureEffect } from '@buzzeasy/shared-frontend-utilities';
import { Method } from '@buzzeasy/shared-frontend-utilities/src/signalr';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useThrottledCallback } from 'use-debounce';
import { IHeaderUpdate } from '../models/signalR/HeaderUpdate';
import { StartConversationCommand } from '../models/signalR/StartConversationCommand';
import { IMessageToCustomer } from '../models/signalR/ToCustomerMessage';
import { ITypingIndicator } from '../models/signalR/TypingIndicator';
import { useConfigContext } from '../providers/ConfigProvider';
import { useAppDispatch } from '../redux/hooks';
import { setConnectionStatus } from '../redux/widget/widgetSlice';
import { IConversationData } from '../utils/conversationDataHelper';

interface IHandlers {
  onMessageReceived?(message: IMessageToCustomer): void;
  onHeaderUpdated?(headerData: IHeaderUpdate): void;
  onTyping(typingIndicator: ITypingIndicator): void;
  onConversationEnded?(): void;
}

export interface IChatHub {
  start(): Promise<unknown>;
  sendCustomerMessage(text: string, thirdPartyConversationData?: Partial<IConversationData>): void;
  sendCustomerAttachment(attachment: { name: string, contentUrl: string, contentType: string }, thirdPartyConversationData?: Partial<IConversationData>): void;
  sendCustomerTyping(): void;
  endConversation(): void;
  startConversationWithAgent(conversationData: StartConversationCommand): void;
  disconnect(): void;
}

const useChatHub = ({
  onMessageReceived,
  onHeaderUpdated,
  onTyping,
  onConversationEnded,
}: IHandlers): IChatHub => {
  // NOTE: connection mutates internally
  const [connection, setConnection] = useState<HubConnection>();
  const messageQueueRef = useRef<{ method: string; arg?: unknown }[]>([]);

  const config = useConfigContext();
  const dispatch = useAppDispatch();

  const sendQueuedMessages = useCallback(
    (hubConnection: HubConnection | undefined) => {
      if (hubConnection?.state === HubConnectionState.Connected && messageQueueRef.current.length > 0) {
        messageQueueRef.current.forEach(m => hubConnection.send(m.method, m.arg));
        messageQueueRef.current = [];
      }
    },
    [],
  );

  useEffect(
    () => {
      return () => {
        connection?.stop();
      };
    },
    [connection],
  );

  const eventHandlers = useMemo<Method[]>(
    () => {
      return [
        { name: 'ReceiveMessage', handler: onMessageReceived },
        { name: 'UpdateHeader', handler: onHeaderUpdated },
        { name: 'ShowTypingIndicator', handler: onTyping },
        { name: 'EndConversation', handler: onConversationEnded },
      ] as Method[];
    },
    [onConversationEnded, onHeaderUpdated, onMessageReceived, onTyping],
  );

  usePureEffect(
    ({ dispatch: _dispatch, sendQueuedMessages: _sendQueuedMessages }) => {
      eventHandlers.forEach(m => connection?.on(m.name, m.handler));
      connection?.onclose(() => _dispatch(setConnectionStatus('disconnected')));
      connection?.onreconnecting(() => { _dispatch(setConnectionStatus('reconnecting')); });
      connection?.onreconnected(() => { _sendQueuedMessages(connection); _dispatch(setConnectionStatus('connected')); });

      return () => {
        eventHandlers.forEach(m => connection?.off(m.name));
      };
    },
    [connection, eventHandlers, sendQueuedMessages],
    { dispatch, sendQueuedMessages },
  );

  const createConnection = useCallback(
    () => {
      const newConnection = new HubConnectionBuilder()
        .withUrl(config.chatHubUrl)
        .withAutomaticReconnect()
        .build();

      setConnection(newConnection);

      return newConnection;
    },
    [config.chatHubUrl],
  );

  const start = useCallback(
    async () => {
      let hubConnection: HubConnection | undefined = connection;
      if (connection === undefined) {
        hubConnection = createConnection();
      }

      if (hubConnection?.state === HubConnectionState.Disconnected) {
        dispatch(setConnectionStatus('connecting'));
        try {
          await hubConnection.start();
          sendQueuedMessages(hubConnection);
          dispatch(setConnectionStatus('connected'));
        }
        catch {
          dispatch(setConnectionStatus('disconnected'));
        }
      }
    },
    [connection, createConnection, dispatch, sendQueuedMessages],
  );

  const send = useCallback(
    (method: string, arg?: unknown, shouldStart = true) => {
      if (connection?.state === HubConnectionState.Connected) {
        if (arg) // This is if-else NOT pointless. This is actually required for correct send behavior.
          connection.send(method, arg);
        else
          connection.send(method);
      }
      else if (shouldStart) {
        start();
        messageQueueRef.current.push({ method, arg });
      }
    },
    [connection, start],
  );

  const sendCustomerTyping = useCallback(() => send('CustomerIsTyping', undefined, false), [send]);
  const throttledCustomerTyping = useThrottledCallback(sendCustomerTyping, 3000, { leading: true });

  return useMemo<IChatHub>(
    () => ({
      start,
      sendCustomerMessage: (messageBody, data) => send('SendCustomerMessage', { messageBody, workflowData: data?.workflowData, customerInfo: data?.customerData }),
      sendCustomerAttachment: (attachment, data) => send('SendCustomerMessage', { attachments: [attachment], workflowData: data?.workflowData, customerInfo: data?.customerData }),
      sendCustomerTyping: throttledCustomerTyping,
      endConversation: () => send('CloseConversation'),
      startConversationWithAgent: (conversationData) => send('StartConversationWithAgent', conversationData.agentData),
      disconnect: () => connection?.stop(),
    }),
    [connection, send, start, throttledCustomerTyping],
  );
};

export default useChatHub;