import { usePureEffect } from '@buzzeasy/shared-frontend-utilities';
import { Method } from '@buzzeasy/shared-frontend-utilities/src/signalr';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { StartConversationCommand } from '../models/signalR/StartConversationCommand';
import { CustomerTrackingDetails } from '../models/signalR/Tracking';
import { useConfigContext } from '../providers/ConfigProvider';
import { isConversationActive } from '../utils/conversationHelper';
import { getOrCreateItem } from '../utils/localStorageHelpers';

const clientIdStorageKey = 'buzzeasy_client_id';
const siteOpenedAtStorageKey = 'buzzeasy_tab_opened_at';

const urlVisitedAt = new Date();

const getOrCreateSiteOpenedAt = () => {
  let siteOpenedAtFromStorage = sessionStorage.getItem(siteOpenedAtStorageKey);
  if (siteOpenedAtFromStorage === null) {
    siteOpenedAtFromStorage = urlVisitedAt.toISOString();
    sessionStorage.setItem(siteOpenedAtStorageKey, siteOpenedAtFromStorage);
  }
  return new Date(siteOpenedAtFromStorage);
};

export function getTrackingDetails(): CustomerTrackingDetails {
  return {
    siteOpenedAt: getOrCreateSiteOpenedAt(),
    urlVisitedAt,
    pageTitle: document.title,
    url: window.location.href,
  };
}

interface IHandlers {
  onStartConversation(message: StartConversationCommand): void;
}

const useCustomerTrackingHub = ({ onStartConversation }: IHandlers) => {
  const [connection, setConnection] = useState<HubConnection>();
  const [lastPath, setLastPath] = useState<string>();
  const clientId = useMemo(() => getOrCreateItem<string>(clientIdStorageKey, nanoid()), []);

  const config = useConfigContext();

  const createConnection = useCallback(
    () => {
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${config.trackingHubUrl}&clientId=${clientId}`)
        .withAutomaticReconnect()
        .build();
      setConnection(newConnection);
    },
    [clientId, config.trackingHubUrl],
  );

  useEffect(
    () => {
      return () => {
        connection?.stop();
      };
    },
    [connection],
  );

  const eventHandlers = useMemo<Method[]>(
    () => {
      return [
        { name: 'StartConversation', handler: onStartConversation },
      ] as Method[];
    },
    [onStartConversation],
  );

  useEffect(
    () => {
      eventHandlers.forEach(m => connection?.on(m.name, m.handler));

      return () => {
        eventHandlers.forEach(m => connection?.off(m.name));
      };
    },
    [connection, eventHandlers],
  );

  const send = useCallback(
    async (method: string, arg: unknown) => {
      if (connection?.state === HubConnectionState.Disconnected) {
        await connection?.start();
      }

      connection?.send(method, arg);
    },
    [connection],
  );

  const tracking = useCallback(
    (location: Location) => {
      if (!config.settings.customerTracking.pages.some((trackedPath) => location.pathname.startsWith(trackedPath))) {
        connection?.stop();
        return;
      }

      send('AddOrUpdateSiteInteraction', getTrackingDetails());
    },
    [config.settings.customerTracking.pages, connection, send],
  );

  usePureEffect(
    ({ createConnection: _createConnection, tracking: _tracking }) => {
      if (!config.settings.customerTracking.enabled) {
        return;
      }

      const pathPolling = setInterval(() => {
        if (connection) {
          if (isConversationActive(config.channelId)) {
            connection.stop();
          }
          else {
            const currentPath = window.location.pathname;
            if (currentPath !== lastPath) {
              setLastPath(currentPath);
              _tracking(window.location);
            }
          }
        }
        else {
          _createConnection();
        }
      }, 1000);

      return () => clearInterval(pathPolling);
    },
    [config.channelId, config.settings.customerTracking.enabled, connection, lastPath],
    { createConnection, tracking },
  );
};

export default useCustomerTrackingHub;